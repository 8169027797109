<template>
  <div class="flex justify-between items-center">
    <div class="flex">
      <VAction class="-ml-2" icon="lessons" />

      <VLine :label="$t('app.lessons', 2)" />

      <div>
        {{ training?.lesson_count }}
      </div>
    </div>

    <div class="flex">
      <VAction icon="evaluations" />

      <VLine :label="$t('app.evaluations', 2)" />

      <div>
        {{ training?.evaluation_count }}
      </div>
    </div>

    <div class="flex">
      <VAction icon="attestations" />

      <VLine :label="$t('app.attestations', 2)" />

      <div>
        {{ training?.attestation_count }}
      </div>
    </div>
  </div>
</template>

<script>
// Components
import VAction from "@/components/tables/VAction";
import VLine from "@/components/VLine";

export default {
  components: {
    VAction,
    VLine
  },
  props: {
    training: {
      type: Object,
      required: true
    }
  }
};
</script>
