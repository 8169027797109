<template>
  <BlogCard
    :title-class="isView ? '' : 'cursor-pointer'"
    :color="training?.colour"
    @click="$emit('click')"
  >
    <template #image>
      <img
        v-if="getAvatarURL(training?.image?.view_path)"
        :src="getAvatarURL(training?.image?.view_path)"
      />
    </template>

    <template #status>
      <slot name="status" />
    </template>

    <template #author>
      {{ training?.author }}
    </template>

    <template #title>
      {{ getText(training?.texts, "name") }}
    </template>

    <template #content>
      <TrainingContent :training="training" class="p-5">
        <template #more-content>
          <slot name="more-content" />
        </template>
      </TrainingContent>

      <TrainingSummary class="px-5 pb-5" :training="training" />
    </template>
  </BlogCard>
</template>

<script>
// Composables
import useDisplay from "@/composables/useDisplay";
import useColor from "@/composables/useColor";
import useTexts from "@/composables/useTexts";
// Components
import TrainingContent from "./TrainingContent";
import BlogCard from "@/components/BlogCard";
import TrainingSummary from "./TrainingSummary";

export default {
  components: {
    TrainingContent,
    BlogCard,
    TrainingSummary
  },
  props: {
    training: {
      type: Object,
      required: true
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"],
  setup() {
    // Composables
    const { getText } = useTexts();
    const { getStatusColor } = useColor();
    const { getAvatarURL } = useDisplay();

    return {
      // useTexts
      getText,
      // useColor
      getStatusColor,
      // useDisplay
      getAvatarURL
    };
  }
};
</script>
